// https://github.com/reactstrap/reactstrap/blob/c41b172180b92ba3f53444ca8f409c1cd10f895d/src/utils.js#L4
export const getScrollbarWidth = () => {
  let scrollDiv = document.createElement('div')

  scrollDiv.style.position = 'absolute'
  scrollDiv.style.top = '-9999px'
  scrollDiv.style.width = '50px'
  scrollDiv.style.height = '50px'
  scrollDiv.style.overflow = 'scroll'
  document.body.appendChild(scrollDiv)

  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth
  document.body.removeChild(scrollDiv)

  return scrollbarWidth
}
