export const Dots = ({ dotIndex, numDots }) => {
  let dots = []

  if (numDots > 0) {
    for (let i = 0; i < numDots; i++) {
      dots.push(<Styles.Dot key={i} isActive={i === dotIndex} />)
    }
  }

  return (
    <React.Fragment>
      {dots.length > 0 && <Styles.DotsContainer>{dots}</Styles.DotsContainer>}
    </React.Fragment>
  )
}

Dots.propTypes = {
  dotIndex: PropTypes.number,
  numDots: PropTypes.number,
}

const Styles = {
  DotsContainer: styled.div`
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);

    > span + span {
      margin-left: 9px;
    }
  `,
  Dot: styled.span`
    display: inline-block;
    width: 9px;
    height: 9px;
    border-radius: 50%;

    background-color: #979797;
    transition: background-color 300ms linear;
    -webkit-transition: background-color 300ms linear;
    -ms-transition: background-color 300ms linear;

    ${({ isActive }) =>
      isActive &&
      css`
        background-color: white;
      `}
  `,
}

export default Dots
