interface DismissProps {
  className?: string
  redStyling?: boolean
}

export const Dismiss: React.FC<DismissProps> = ({ className, redStyling = false }) =>
  redStyling ? (
    <svg viewBox='0 0 13 12' fill='none' className={className}>
      <path
        d='M12.5201 10.4355C12.8785 10.7939 12.8788 11.3695 12.5212 11.7284C12.3437 11.9118 12.1111 12 11.8756 12C11.6422 12 11.4061 11.9045 11.2311 11.7296L6.79449 7.29322L2.35905 11.7284C2.18105 11.9124 1.94296 12 1.71342 12C1.48006 12 1.24392 11.9045 1.06899 11.7296C0.710085 11.3708 0.710061 10.7941 1.06901 10.4353L5.50559 5.99921L1.06899 1.56318C0.710152 1.20438 0.71 0.627728 1.06899 0.269141C1.42761 -0.0897902 2.0043 -0.0896375 2.36312 0.269142L6.79976 4.70521L11.2364 0.269142C11.5953 -0.0897139 12.172 -0.0897139 12.5309 0.269142C12.8895 0.627702 12.8894 1.20429 12.531 1.56309L8.09377 5.99937L12.5201 10.4355Z'
        fill='#FF0000'
      />
    </svg>
  ) : (
    <svg width='14' height='16' viewBox='0 0 14 16' className={className}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='square'>
        <g transform='translate(1.000000, 1.000000)' stroke='#000000' strokeWidth='1.5'>
          <path d='M0,13.9285714 L11.5714286,0.928571429' />
          <path d='M0,0.928571429 L12,13.9285714' />
        </g>
      </g>
    </svg>
  )
