import { Dismiss } from 'src/components/Display/Dismiss'

const Header = ({
  onClose = Function.prototype,
  onClosed = Function.prototype,
  headerLeft = <div />,
  isDismissDisabled = false,
  headerClassName = '',
  children,
  leftCloseButton = false,
  ...props
}) => (
  <Styles.HeaderContainer {...props} leftCloseButton={leftCloseButton}>
    {headerLeft}
    {!!children && <Styles.Header className={headerClassName}>{children}</Styles.Header>}
    <Styles.Dismiss
      className="dialog-dismiss"
      isDisabled={isDismissDisabled}
      onClick={() => {
        if (typeof onClose === 'function') onClose()
        if (typeof onClosed === 'function') setTimeout(() => onClosed(), 250)
      }}
      disabled={isDismissDisabled}
    >
      <Dismiss />
    </Styles.Dismiss>
  </Styles.HeaderContainer>
)

const Styles = {
  HeaderContainer: styled.div`
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: ${({ leftCloseButton }) => (leftCloseButton ? 'row-reverse' : 'row')};

    ${({ noBorder = false }) =>
      !noBorder &&
      css`
        border-bottom: 1px solid #dee2e6;
      `}
  `,
  Header: styled.h2`
    font-size: 1.25rem;
    font-weight: 300;
    margin-bottom: 0;
  `,
  Dismiss: styled.button`
    background: none;
    color: inherit;
    border: none;
    outline: inherit;

    ${({ isDisabled }) =>
      isDisabled &&
      css`
        opacity: 0.25;
        :focus {
          outline: 0;
        }
      `}
  `,
}

export default Header
